import { Loading } from '../components/Loading/Loading';
import { useRouter } from 'next/router';
import { useContext, useEffect, useState } from 'react';
import { useOktaAuth } from '../contexts/OktaContext';
import { ErrorMessage } from '../components/ErrorMessage/ErrorMessage';
import { LOGIN_PAGE_URL, WELCOME_URL } from '../utils/routes';
import { DefaultRedirectContext } from '../contexts/DefaultRedirectContext';
import { getCookie } from '../utils/storageUtils';

interface OktaError {
  name?: string;
  errorCode?: string;
  errorSummary?: string;
}

function Login() {
  const { oktaAuth, authState } = useOktaAuth();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const { push } = useRouter();
  const { redirectURL, welcomePagePrefs } = useContext(DefaultRedirectContext);

  useEffect(() => {
    const getToken = async () => {
      // If the urs is a redirect from Okta, get the token from URL and set that
      if (oktaAuth?.isLoginRedirect()) {
        try {
          const { tokens } = await oktaAuth.token.parseFromUrl();
          oktaAuth.tokenManager.setTokens(tokens);
        } catch (error) {
          console.error(error);
          setErrorMessage(
            (error as OktaError)?.errorSummary ??
              'We’re having trouble getting this information, please try again later.'
          );
        }
      } else {
        // If the user is visiting url directly, check if idToken and accessToken is in local storage
        const tokens = oktaAuth?.tokenManager.getTokensSync();

        if (!tokens || !tokens.idToken || !tokens.accessToken) {
          // If no token, perform a redirect.
          oktaAuth?.token.getWithRedirect({
            pkce: true,
            scopes: ['openid', 'profile', 'email']
          });
        } else {
          // Store those tokens in okta tokenManager
          oktaAuth?.tokenManager.setTokens(tokens);
        }
      }
    };

    if (oktaAuth) {
      getToken();
    }
  }, [oktaAuth]);

  useEffect(() => {
    if (authState?.isAuthenticated && !!redirectURL) {
      const rootUrl = `${window.location.protocol}//${window.location.host}`;
      const storedURL = oktaAuth?.getOriginalUri() ?? redirectURL;

      if (
        welcomePagePrefs.showWelcomePageOnStartup &&
        !getCookie('welcome_was_shown')
      ) {
        push(WELCOME_URL);
      } else {
        // Add extra check to make sure the user is not redirecting to login page
        if (
          storedURL?.endsWith(LOGIN_PAGE_URL) ||
          storedURL?.endsWith(`${LOGIN_PAGE_URL}/`) ||
          rootUrl === storedURL ||
          `${rootUrl}/` === storedURL
        ) {
          push(redirectURL);
        } else {
          push(storedURL);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState?.isAuthenticated, redirectURL]);

  return (
    <div className="h-screen flex flex-col items-center justify-center">
      {errorMessage ? (
        <ErrorMessage
          errorMessage={`${errorMessage} Contact MarineSupport@crowley.com for access.`}
        />
      ) : (
        <Loading />
      )}
    </div>
  );
}

export default Login;
